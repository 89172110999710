<template>
  <div class="members-directory">
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button @click="$router.go(-1)" size="25" class="btn customBtn pattern-btn white--text">
            <span> {{ $t("marketingCampaign.back") }}</span>
          </button>
          <h3 class="box-title green-text">
            {{ $t("marketingCampaign.addCampaignTitle") }}
          </h3>
        </div>
      </div>
      <div class="custom-card vehicle-car">
        <div class="custom-box">
          <v-form>
            <v-row>
              <v-col cols="12" md="12"><b>{{ $t("marketingCampaign.totalSelectedContacts") }}
                {{ (contacts && contacts.length) || 0 }}</b>
              </v-col>
              <v-col cols="12" md="12">
                <label class="form-label">{{ $t("marketingCampaign.enterCampaignMessage") }}</label>
                <v-textarea hide-details="auto" :placehoder="$t('marketingCampaign.enterCampaignMessage')"
                  v-model="data.message" @input="$v.data.message.$touch()" @blur="$v.data.message.$touch()"
                  :error-messages="messageErrors" :maxlength="100" solo></v-textarea>
                <span class="info-msg">{{ $t("marketingCampaign.remainingCharacter") }} {{ messageCount }}/100</span>
              </v-col>
              <v-col cols="12">
                <label class="form-label">
                      {{ $t("marketingCampaign.uploadImageTitle") }}
                    </label>
                <div class="
                            flex w-full h-screen items-center justify-center text-center fileuploads rd-fileUpload
                          " id="app">
                  <v-progress-circular v-if="fileLoader" indeterminate :size="40" color="green"></v-progress-circular>
                  <div class="" @dragover="dragover" @drop="drop" v-if="!fileLoader">
                    <input type="file" name="file" id="assetsFieldHandle" @change="onChange" ref="file"
                      accept=".jpg,.jpeg,.png" />
                    <label for="assetsFieldHandle" class="fileuploadlabel">
                      {{ $t("marketingCampaign.uploadImageTitle") }}
                    </label>
                    <ul class="img-preview-list-wrapper" v-if="filelist.length">
                      <li class="img-preview-list" v-for="(file, index) in filelist" :key="index">
                        <div class="img-preview-list-img-wrap">
                          <img :src="file.data.url" width="50px" />
                          <span class="img-preview-list-helper">
                            <!-- {{ file.data && file.data.name }} -->
                            <a type="button" @click="remove(filelist.indexOf(file), file.data)"
                              title="Remove file">
                              <v-icon>mdi-delete</v-icon>
                            </a>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn height="45" hide-details="auto" large color="green darkan-4 green-gradient white--text"
                  class="btn customBtn pattern-btn text-capitalize btn-submit" @click="submitCampaign" :disabled="!data.message
                    ">
                  {{ $t("marketingCampaign.submit") }}
                  <template v-slot:loading>
                    <v-icon>mdi-loading</v-icon>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <v-dialog hide-overlay :attach="true" v-model="priceDialog" max-width="700px"
      :content-class="'hide-overflow-y image-slider'" persistent>
      <v-card>
        <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
          <div class="pa-2">
            <v-form>
              <v-container v-if="priceDailogContent">
                <v-form class="mr-3">
                  <v-col class="text-center align-self-end justify-center">
                    <h2 class="mb-3"> Total Cost of Campaign </h2>
                    <h3 class="text--green" style="font-size: 40px !important;"> ${{ (contacts.length*PER_CONTACT_COST)+PER_CAMPAIGN_COST }} </h3>
                  </v-col>
                  <v-row>
                    <template>
                      <v-container fluid>
                        <v-checkbox v-model="termsConditions">
                          <template v-slot:label>
                            <div>
                              {{ $t("marketingCampaign.iAgreeTerms") }}
                            </div>
                          </template>
                        </v-checkbox>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              :href="TERMS_CONDITIONS_LINK"
                              @click.stop
                              v-on="on"
                            >
                              {{ $t("marketingCampaign.term&condition") }}
                            </a>
                          </template>
                          Opens in new window
                        </v-tooltip>
                      </v-container>
                    </template>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize"
                      @click="priceDialog = !priceDialog;">
                      {{ $t("marketingCampaign.no") }}
                    </v-btn>
                    <v-btn height="40" large color="green darken-4 green-gradient white--text text-capitalize"
                      :loading="saving"
                      @click="addCampaign"
                      :disabled="!termsConditions"
                      style="font-size: 20px !important;">
                      {{ $t("marketingCampaign.yes") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
              <v-container v-if="!priceDailogContent">
                <v-card-text class="text-pre-wrap green white--text">
                  {{ $t("marketingCampaign.compaignStart") }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn height="40" large color="green darken-4 green-gradient white--text text-capitalize"
                    :loading="saving"
                    @click="redirectToSuccess"
                    style="font-size: 20px !important;">
                    {{ $t("marketingCampaign.okay") }}
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbarSuccess"
      :timeout="3000"
      absolute
      top
      color="success"
      right
    >
      {{ $t("marketingCampaign.compaignStart") }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbarSuccess = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { PER_CAMPAIGN_COST, PER_CONTACT_COST } from "@/constants/common";

export default {
  props: ['contacts'],
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      TERMS_CONDITIONS_LINK: process.env.TERMS_CONDITIONS_LINK,
      PER_CAMPAIGN_COST: PER_CAMPAIGN_COST,
      PER_CONTACT_COST: PER_CONTACT_COST,
      vinLoading: false,
      fileLoader: false,
      loading: false,
      saving: false,
      messageError: [],
      apiErrorMessage: null,
      filelist: [],
      priceDialog: false,
      termsConditions: false,
      snackbarSuccess: false,
      priceDailogContent: true,
      data: {
        media_ids: [],
        message: null,
        contacts: []
      },
      messageCount: 0,
      errors: {
        message: null
      }
    };
  },
  watch: {
    'data.message': function (val) {
      this.messageCount = 100 - (val ? val.length : 0);
    },
  },
  validations: {
    data: {
      message: { required, maxLength: maxLength(100) },
    },
  },
  computed: {
    ...mapGetters({
      imageResponse: "bookLog/getImageResponse",
    }),
    messageErrors() {
      const errors = [];
      if (!this.$v.data.message.$dirty) return errors;
      if (this.messageError && this.messageError.length) {
        return this.messageError;
      }
      !this.$v.data.message.required &&
        errors.push(this.$t("marketingCampaign.validations.messageIsRequired"));
      !this.$v.data.message.maxLength &&
        errors.push(this.$t("marketingCampaign.validations.messageMaxLength"));
      return errors;
    },
  },
  mounted() {
    if (!this.contacts || this.contacts.length == 0) {
      this.$router.go(-1);
    } else {
      this.data.contacts = this.contacts;
    }
  },
  methods: {
    ...mapActions({
      uploadImage: "bookLog/uploadImage",
      deleteImage: "bookLog/deleteImage",
      saveMarketingCompaign: "marketingCampaign/saveMarketingCompaign",
    }),
    submitCampaign() {
      this.priceDialog = true;
    },
    async addCampaign() {
      try {
        this.saving = true;
        await this.saveMarketingCompaign(this.data);
        // this.snackbarSuccess = true
        this.priceDailogContent = false
        // setTimeout(() => {
        //   this.resetFormData();
        //   this.$router.push({ name: 'MarketingCampaign' });
        //   this.priceDailogContent = true;
        // }, 2000);
      } catch ({ message }) {
        this.errors.message = message.message;
        // this.apiErrorMessage = message;
      } finally {
        this.saving = false;
      }
    },
    redirectToSuccess() {
      this.resetFormData();
      this.$router.push({ name: 'HistoryCampaign' });
      this.priceDailogContent = true;
    },
    resetFormData() {
      this.data = {
        message: null,
        contacts: [],
        media_ids: []
      }
    },
    async onChange() {
      try {
        if (
          this.$refs.file.files[0].type === "image/jpeg" ||
          this.$refs.file.files[0].type === "image/png"
        ) {
          this.saveImage(this.$refs.file.files[0]);
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },
    async remove(i, file) {
      try {
        this.fileLoader = true;
        await this.deleteImage(file.id);
        await this.filelist.splice(i, 1);
        this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.fileLoader = false;
      }
    },
    async saveImage(file) {
      try {
        this.fileLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await this.uploadImage(formData);
        this.fileLoader = false;
        this.filelist = [...this.filelist, this.imageResponse];
        this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>